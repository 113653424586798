//App.js

import React from 'react';
import './App.css';
import MyThreeScene from './MyThreeScene';
import { Route, Routes } from 'react-router-dom';
import Contact from './Contact'; 

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Routes>
          <Route path="/" element={<MyThreeScene />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </header>
    </div>
  );
}

export default App;
