import React, { useEffect, useState } from 'react';
import './TickerBanner.css';

const TickerBanner = ({ message }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    // Wait for a short delay before starting the animation
    const timer = setTimeout(() => {
      setShouldAnimate(true);
    }, 1000); // Adjust the delay as needed

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="ticker-banner">
      <div className={`ticker-content ${shouldAnimate ? 'animate' : ''}`}>
        <span>{message}</span>
      </div>
    </div>
  );
};

export default TickerBanner;
