import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import * as THREE from 'three';
import logo from './logo.png'; // Adjust the path as necessary
import { db } from "./firebase";
import { collection, addDoc } from "firebase/firestore"; 
import './animation.css';
import TickerBanner from './TickerBanner';
import { Link } from 'react-router-dom';

const Stars = () => {
  const starsRef = useRef();
  const [starCount] = useState(100000); // Number of stars

  // Create star field
  const starGeo = new THREE.BufferGeometry();
  const positions = new Float32Array(starCount * 3);
  const colors = new Float32Array(starCount * 3);

  for (let i = 0; i < starCount; i++) {
    positions[i * 3] = (Math.random() - 0.5) * 1000;
    positions[i * 3 + 1] = (Math.random() - 0.5) * 600;
    positions[i * 3 + 2] = (Math.random() - 0.5) * 1600;
    colors[i * 3] = colors[i * 3 + 1] = colors[i * 3 + 2] = 1;
  }

  starGeo.setAttribute('position', new THREE.BufferAttribute(positions, 3));
  starGeo.setAttribute('color', new THREE.BufferAttribute(colors, 3));

  const starMaterial = new THREE.PointsMaterial({
    size: 0.7,
    vertexColors: true,
    transparent: true,
    opacity: 1,
    blending: THREE.AdditiveBlending,
  });

  return <points ref={starsRef} geometry={starGeo} material={starMaterial} />;
};

const FloatingText = () => {
  return (
    <Text
      font='./blipfont.ttf'
      style={{ fontFamily: 'YourFontName' }}
      color="cyan"
      anchorX="center"
      anchorY="middle"
      position={[0, 250, 0]}
      fontSize={90}
    >
      BLIP 31.69
    </Text>
  );
};

const FloatingTextt = () => {
  return (
    <Text
      font='./blipfont.ttf'
      className="YourFontName"
      color="cyan"
      anchorX="center"
      anchorY="middle"
      position={[0, -30, 430]}
      fontSize={10}
    >
      Helping pass memories down since 2023
    </Text>
  );
};

const LoginBox = ({ show }) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (email || phone) {
      try {
        const docRef = collection(db, 'beta');
        await addDoc(docRef, {
          email: email,
          phone: phone,
        });
  
        setEmail('');
        setPhone('');
        alert('Data submitted successfully!');
      } catch (error) {
        console.error('Error adding document: ', error);
        alert('An error occurred while submitting the data. Please try again.');
      }
    } else {
      alert('Please enter either an email / phone  or both.');
    }
  };
  
  if (!show) return null;

  return (
    <div
      style={{
        fontFamily: 'YourFontName', // Add this line
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'rgba(255, 255, 255, 0.8)',
        padding: '20px',
        borderRadius: '10px',
        width: '300px',
        // margin: '20px', 
        lineHeight: '1.5', 
      }}
    >
      <h3>Get Notified About Beta</h3>
      <p>Enter your email or phone number to be notified:</p>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={handleEmailChange}
        />
        <br />
        <p style={{ textAlign: 'center' }}>or</p>
        <label htmlFor="phone">Phone:</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          value={phone}
          onChange={handlePhoneChange}
        />
        <br />
        <input type="submit" value="Notify Me" />
      </form>
    </div>
  );
};

const MyThreeSceneContent = ({ setShowLoginBox }) => {
  const controlsRef = useRef();
  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    // Set animation completed after 2 seconds
    const timer = setTimeout(() => {
      setAnimationCompleted(true);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useFrame(({ camera }) => {
    if (animationCompleted) {
      // Automatically zoom in after 2 seconds
      if (camera.position.z > 70) {
        camera.position.z -= 10;
      } else {
        camera.position.z = 70;
        camera.updateProjectionMatrix();
      }

      const zoomLevel = camera.position.distanceTo(new THREE.Vector3(0, 0, 0));
      if (zoomLevel <= 70) {
        setShowLoginBox(true);
      } else {
        setShowLoginBox(false);
      }
    }
  });

  return (
    <>
      <ambientLight intensity={0.8} />
      <pointLight color="white" intensity={1} position={[0, 0, 0]} />
      <Stars />
      <FloatingText /> {/* Include the floating text here */}
      <FloatingTextt />
    </>
  );
};

const MyThreeScene = () => {
  const [showLoginBox, setShowLoginBox] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the user is on a mobile or tablet device
    const userAgent = navigator.userAgent.toLowerCase();
    setIsMobile(
      /(android|webos|iphone|ipad|ipod|blackberry|windows phone)/.test(userAgent)
    );
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'black',
      }}
    >
      <Link
        to="/contact"
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px', // Adjust the right position as needed
          color: 'white',
          zIndex: 1000,
          fontSize: '24px',
          paddingTop: '10px',
        }}
      >
        Contact
      </Link>

      <img
        src={logo}
        alt="Logo"
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          width: '50px',
        }}
      />

      <Canvas
        camera={{ position: [0, 0, 920], fov: 75 }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <MyThreeSceneContent setShowLoginBox={setShowLoginBox} />
      </Canvas>
      {showLoginBox && <LoginBox show={showLoginBox} />}
      <TickerBanner message="BLIP stands for BEING LOVING INSPIRATIONAL and PERSONAL. The number 31.69 represents the years it takes to reach a Billion seconds 1,000,000,000 seconds." />
    </div>
  );
};

export default MyThreeScene;
