import React from 'react';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const emailStyle = {
    color: '#555',
  };

  const atSymbolStyle = {
    fontFamily: 'Arial, sans-serif',
  };

  return (
    <div style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
      <button 
        onClick={handleBackClick} 
        style={{
          position: 'fixed',
          top: '10px',
          left: '10px',
          backgroundColor: '#333', 
          color: 'white', 
          border: 'none', 
          padding: '10px 20px', 
          cursor: 'pointer', 
          borderRadius: '5px',
          zIndex: 1000 // Ensure it is above other elements
        }}
      >
        &larr; Back
      </button>
      <h1 style={{ color: '#333', fontSize: '24px' }}>Contact Information</h1>
      <p style={emailStyle}>
        blip31.69<span style={atSymbolStyle}>@</span>gmail.com
      </p>
      {/* <p style={{ color: '#555' }}>Phone: +1 (678) 360-9179</p> */}
    </div>
  );
};

export default Contact;
